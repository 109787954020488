import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import BlogPost from '../templates/blog-post';
import { graphql } from 'gatsby';
import CodeExample from '../components/CodeExample';
export const _frontmatter = {
  "title": "Overlapping Grid Items",
  "date": "2018-06-11",
  "promo": "grids",
  "description": "How to build magazine style layouts with CSS Grid",
  "color": "#ff9aff"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = BlogPost;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Now that CSS Grid has arrived, we're starting to see more and more magazine style layouts on the web. And for good reason: they're clean, readable, attractive, and familiar. You might even be tasked with building one right now. If not, you almost certainly will soon.`}</p>
    <p>{`The secret to an awesome magazine-style grid layout is:`}</p>
    <div className="formula">
  1. organize content into a well-structured grid. <br />
  2. break out of the grid with overlapping grid items.
  <br />
    </div>
    <p>{`Now that you know about this little formula you're going to start noticing it `}<em parentName="p">{`everywhere`}</em>{`. On the front and back of your favorite cereal box. In your grandma's magazines. And increasingly, in your favorite web sites/apps.`}</p>
    <p>{`Here's how to build awesome magazine-style grid layouts by overlapping grid items.`}</p>
    <h2>{`How to Overlap CSS Grid Items`}</h2>
    <p>{`In order to overlap grid items, you have to get them positioned into the `}<strong parentName="p">{`same`}</strong>{` grid cells. There are four ways to accomplish that:`}</p>
    <ol>
      <li parentName="ol">{`using grid line numbers`}</li>
      <li parentName="ol">{`using named grid lines`}</li>
      <li parentName="ol">{`using named grid areas`}</li>
      <li parentName="ol">{`spanning grid items`}</li>
    </ol>
    <p>{`Let's take a look at a `}<inlineCode parentName="p">{`3x3`}</inlineCode>{` grid containing two grid items, and compare these overlapping techniques. Let's position Item 1 (purple) into the top row, and Item 2 (red) into the left column, letting the two items overlap in the top left corner (row 1, column 1).`}</p>
    <h3>{`grid line numbers`}</h3>
    <p>{`The browser automatically numbers each grid line, staring with the number `}<inlineCode parentName="p">{`1`}</inlineCode>{` and ending with `}<inlineCode parentName="p">{`number of tracks + 1`}</inlineCode>{`.`}</p>
    <CodeExample live={true} theme="grid" showCSS={`
    .app {
      display: grid;
      grid-template-rows: 100px 1fr 100px;
      grid-template-columns: 150px 1fr 150px;
    }\n
    .item.one {
      grid-row-start: 1;
      grid-row-end: 2;
      grid-column-start: 1;
      grid-column-end: 4;
    }\n
    .item.two {
      grid-area: 1 / 1 / 4 / 2;
    }
  `} fixedCSS={`
    .app {
      display: grid;
      grid-template-rows: 100px 1fr 100px;
      grid-template-columns: 150px 1fr 150px;
      height: 300px;
    }
    .item.one {
      grid-row-start: 1;
      grid-row-end: 2;
      grid-column-start: 1;
      grid-column-end: 4;
    }
    .item.two {
      grid-area: 1 / 1 / 4 / 2;
    }
  `} html={`
    <div class="app">
      <div class="item one">item 1</div>
      <div class="item two special">item 2</div> 
    </div>
  `} mdxType="CodeExample" />
    <p>{`For item 1 we used the longhand `}<inlineCode parentName="p">{`grid-[row|column]-[start|end]`}</inlineCode>{` keywords. For item 2 we used the convenient `}<inlineCode parentName="p">{`grid-area`}</inlineCode>{` shorthand that sets all four positioning settings at once.`}</p>
    <p>{`Since both items are instructed to occupy the top left cell of the grid, they overlap.`}</p>
    <h3>{`named grid lines`}</h3>
    <p>{`Using the automatic line numbers is straightforward enough, but can become hard to work with later if you're changing your Grid a bunch, like in a responsive design or even just changing your mind while you work on the layout.`}</p>
    <p>{`So instead of numbers we can name the lines ourselves, and position our items using our custom line names.`}</p>
    <CodeExample live={true} theme="grid" showCSS={`
    .app {
      display: grid;
      grid-template-rows: [header-start] 100px [header-end content-start] 1fr [content-end footer-start] 100px [footer-end];
      grid-template-columns: [sidebar-start header-start footer-start] 150px [sidebar-end content-start] 1fr 150px [content-end header-end footer-end];
    }\n
    .item.one {
      grid-area: header-start / sidebar-start / header-end / content-end;
    }\n
    .item.two {
      grid-area: header-start / sidebar-start / footer-end / sidebar-end;
    }
  `} fixedCSS={`
    .app {
      display: grid;
      grid-template-rows: [header-start] 100px [header-end content-start] 1fr [content-end footer-start] 100px [footer-end];
      grid-template-columns: [sidebar-start header-start footer-start] 150px [sidebar-end content-start] 1fr 150px [content-end header-end footer-end];
    }
    .item.one {
      grid-area: header-start / sidebar-start / header-end / content-end;
    }
    .item.two {
      grid-area: header-start / sidebar-start / footer-end / sidebar-end;
    }
  `} html={`
    <div class="app">
      <div class="item one">item 1</div>
      <div class="item two special">item 2</div> 
    </div>
  `} mdxType="CodeExample" />
    <p>{`The syntax for naming lines is `}<em parentName="p">{`line, track, line`}</em>{`. Here's a `}<a parentName="p" {...{
        "href": "/post/naming-css-grid-lines"
      }}>{`full guide on naming grid lines`}</a>{`.`}</p>
    <h3>{`named grid areas`}</h3>
    <p>{`Named grid areas are my go-to when I start building a new grid layout. The `}<inlineCode parentName="p">{`grid-template-areas`}</inlineCode>{` property is essentially just a shortcut for quickly naming your grid lines.`}</p>
    <CodeExample live={true} theme="grid" showCSS={`
    .app {
      display: grid;
      grid-template-rows: 100px 1fr 100px;
      grid-template-columns: 150px 1fr 150px;
      grid-template-areas: "header header header"
                          "sidebar content content" 
                          "footer footer footer";
    }\n
    .item.one {
      grid-area: header-start / sidebar-start / header-end / content-end;
    }\n
    .item.two {
      grid-area: header-start / sidebar-start / footer-end / sidebar-end;
    }
  `} fixedCSS={`
    .app {
      display: grid;
      grid-template-rows: 100px 1fr 100px;
      grid-template-columns: 150px 1fr 150px;
      grid-template-areas: "header header header"
                          "sidebar content content" 
                          "footer footer footer";
    }
    .item.one {
      grid-area: header-start / sidebar-start / header-end / content-end;
    }
    .item.two {
      grid-area: header-start / sidebar-start / footer-end / sidebar-end;
    }
  `} html={`
    <div class="app">
      <div class="item one">item 1</div>
      <div class="item two special">item 2</div> 
    </div>
  `} mdxType="CodeExample" />
    <p>{`Notice how we kept the exact same item positioning from the last example, but named our lines in a lot cleaner fashion thanks to `}<inlineCode parentName="p">{`grid-template-areas`}</inlineCode>{`.`}</p>
    <p>{`An important thing to note with grid areas is that cells can only have one name. That top left cell where we overlap things we can't name both "header" and "sidebar". So while we could simplify item 1 to position it into the "header" area, item 2 still needs to use the line names that `}<inlineCode parentName="p">{`grid-template-areas`}</inlineCode>{` created:`}</p>
    <CodeExample live={true} theme="grid" showCSS={`
    .app {
      display: grid;
      grid-template-rows: 100px 1fr 100px;
      grid-template-columns: 150px 1fr 150px;
      grid-template-areas: "header header header"
                          "sidebar content content" 
                          "footer footer footer";
    }\n
    .item.one {
      grid-area: header;
    }\n
    .item.two {
      grid-area: header-start / sidebar-start / footer-end / sidebar-end;
    }
  `} fixedCSS={`
    .app {
      display: grid;
      grid-template-rows: 100px 1fr 100px;
      grid-template-columns: 150px 1fr 150px;
      grid-template-areas: "header header header"
                          "sidebar content content" 
                          "footer footer footer";
    }
    .item.one {
      grid-area: header;
    }
    .item.two {
      grid-area: header-start / sidebar-start / footer-end / sidebar-end;
    }
  `} html={`
    <div class="app">
      <div class="item one">item 1</div>
      <div class="item two special">item 2</div> 
    </div>
  `} mdxType="CodeExample" />
    <h3>{`spanning grid items`}</h3>
    <p>{`The last way to make grid items overlap is by spanning into the same cell.`}</p>
    <CodeExample live={true} theme="grid" showCSS={`
    .app {
      display: grid;
      grid-template-rows: 100px 1fr 100px;
      grid-template-columns: 150px 1fr 150px;
      grid-template-areas: "header header header"
                          "sidebar content content" 
                          "footer footer footer";
    }\n
    .item.one {
      grid-row: 1;
      grid-column: span 3 / -1;
    }\n
    .item.two {
      grid-row-start: 1;
      grid-column-start: 1;
      grid-row-end: span 3;
    }
  `} fixedCSS={`
    .app {
      display: grid;
      grid-template-rows: 100px 1fr 100px;
      grid-template-columns: 150px 1fr 150px;
      grid-template-areas: "header header header"
                          "sidebar content content" 
                          "footer footer footer";
    }
    .item.one {
      grid-row: 1;
      grid-column: span 3 / -1;
    }
    .item.two {
      grid-row-start: 1;
      grid-column-start: 1;
      grid-row-end: span 3;
    }
  `} html={`
    <div class="app">
      <div class="item one">item 1</div>
      <div class="item two special">item 2</div> 
    </div>
  `} mdxType="CodeExample" />
    <p>{`Here we've attached item 1 to the last line on right by using `}<inlineCode parentName="p">{`-1`}</inlineCode>{`, and made it `}<inlineCode parentName="p">{`span 3`}</inlineCode>{` columns `}<em parentName="p">{`startwards`}</em>{` (to the left).`}</p>
    <p>{`Item 2 we've told to start in row 1 column 1, and span 3 rows.`}</p>
    <p>{`When using the spanning method be sure your items specify the `}<em parentName="p">{`row`}</em>{` where you want them, otherwise the `}<em parentName="p">{`grid item placement algorithm`}</em>{` will move them down to the next available empty slot instead of overlapping. To visualize exactly how that works see `}<a parentName="p" {...{
        "href": "/post/grid-item-placement"
      }}>{`how items flow into a grid`}</a>{`.`}</p>
    <h2>{`Control the Stack with Z-Index`}</h2>
    <p>{`Once your grid items are overlapping like you want, you can control which one is on top using `}<inlineCode parentName="p">{`z-index`}</inlineCode>{`.`}</p>
    <p>{`Let's make item 1 be on top for a change:`}</p>
    <CodeExample live={true} theme="grid" showCSS={`
    .app {
      display: grid;
      grid-template-rows: 100px 1fr 100px;
      grid-template-columns: 150px 1fr 150px;
      grid-template-areas: "header header header"
                          "sidebar content content" 
                          "footer footer footer";
    }\n
    .item.one {
      grid-row: 1;
      grid-column: span 3 / -1;
      z-index: 2;
    }\n
    .item.two {
      grid-row-start: 1;
      grid-column-start: 1;
      grid-row-end: span 3;
    }
  `} fixedCSS={`
    .app {
      display: grid;
      grid-template-rows: 100px 1fr 100px;
      grid-template-columns: 150px 1fr 150px;
      grid-template-areas: "header header header"
                          "sidebar content content" 
                          "footer footer footer";
    }
    .item.one {
      grid-row: 1;
      grid-column: span 3 / -1;
      z-index: 2;
    }
    .item.two {
      grid-row-start: 1;
      grid-column-start: 1;
      grid-row-end: span 3;
    }
  `} html={`
    <div class="app">
      <div class="item one">item 1</div>
      <div class="item two special">item 2</div> 
    </div>
  `} mdxType="CodeExample" />
    <h2>{`Let's Build One!`}</h2>
    <p>{`Now that you know how to overlap grid items let's check out a real-world example! Here is a page in PC Magazine talking about Divinity 2: Original Sin 2 game (a way fun little game BTW).`}</p>
    <p><img parentName="p" {...{
        "src": "/img/pcmag.jpg",
        "alt": "pc magazine grid layout"
      }}></img></p>
    <p>{`Here's how we can break this layout up into a grid:`}</p>
    <p><img parentName="p" {...{
        "src": "/gif/pcmag.gif",
        "alt": "pc magazine grid breakdown"
      }}></img></p>
    <p>{`This grid has four columns. The outer columns are a bit wider than the inner ones. We've got three rows - the top one is roughly 130px. The middle row is about twice as big as the bottom row.`}</p>
    <p>{`Let's drop some divs into our grid and see we what we have.`}</p>
    <CodeExample live={true} theme="grid" showCSS={`
    .magazine {
      width: 95vw;
      outline: 6px solid #121219;
      display: grid;
      grid-template-rows: 130px 2fr 1fr;
      grid-template-columns: 1.5fr 1fr 1fr 1.5fr;
    }
  `} fixedCSS={`
    .magazine {
      width: 95vw;
      height: 500px;
      outline: 6px solid #121219;
      display: grid;
      grid-template-rows: 130px 2fr 1fr;
      grid-template-columns: 1.5fr 1fr 1fr 1.5fr;
    }
  `} html={`
    <div class="magazine">
      <div class="item title orange">title</div>
      <div class="item content1 blue">content</div>
      <div class="item content2 blue">content</div>
      <div class="item quote green">quote</div>
      <div class="item image red">image</div>  
      <div class="item score yellow">score</div>
    </div>
  `} mdxType="CodeExample" />
    <p>{`Our divs are being positioned using the default grid placement algorithm. This grid is pretty simple so let's use the grid line numbers approach for positioning.`}</p>
    <CodeExample live={true} theme="grid" showCSS={`
    .magazine {
      width: 95vw;
      height: 90vh;
      outline: 6px solid #121219;
      display: grid;
      grid-template-rows: 75px 2fr 1fr;
      grid-template-columns: 1.5fr 1fr 1fr 1.5fr;
    }\n
    .title {
      grid-row: 1;
      grid-column: 1 / 3;
    }\n
    .image {
      grid-row: 1 / 3;
      grid-column: 2 / 5;
    }\n
    .content1 {
      grid-row: 2 / 4;
      grid-column: 1 / 2;
    }\n
    .content2 {
      grid-row: 3 / 4;
      grid-column: 2 / 4;
    }\n
    .quote {
      grid-row: 2;
      grid-column: 4;
    }\n
    .score {
      grid-row: 3;
    }
  `} fixedCSS={`
    .magazine {
      width: 95vw;
      height: 500px;
      display: grid;
      grid-template-rows: 75px 2fr 1fr;
      grid-template-columns: 1.5fr 1fr 1fr 1.5fr;
    }
    .title {
      grid-row: 1;
      grid-column: 1 / 3;
    }
    .image {
      grid-row: 1 / 3;
      grid-column: 2 / 5;
    }
    .content1 {
      grid-row: 2 / 4;
      grid-column: 1 / 2;
    }
    .content2 {
      grid-row: 3 / 4;
      grid-column: 2 / 4;
    }
    .quote {
      grid-row: 2;
      grid-column: 4;
    }
    .score {
      grid-row: 3;
    }
  `} html={`
    <div class="magazine">
      <div class="item title orange">title</div>
      <div class="item content1 blue">content</div>
      <div class="item content2 blue">content</div>
      <div class="item quote green">quote</div>
      <div class="item image red">image</div>  
      <div class="item score yellow">score</div>
    </div>
  `} mdxType="CodeExample" />
    <p>{`There we go. This grid contains same items as before, but now they're positioned and overlapping how we want them.`}</p>
    <p>{`We had to break the content up into two grid items to achieve this `}<em parentName="p">{`L-shape`}</em>{` of content. The CSS Grid spec doesn't currently have a way to let text flow from one grid item to another, and grid `}<em parentName="p">{`areas`}</em>{` have to be rectangular.`}</p>
    <p>{`At this point the overall layout is pretty much done. If we add a bit of content and refine a couple of styling details it begins to resemble the magazine:`}</p>
    <CodeExample live={true} showCSS={`
    .magazine {
      width: 95vw;
      height: 800px;
      outline: 6px solid #121219;
      display: grid;
      grid-template-rows: 130px 2fr 1fr;
      grid-template-columns: 1.5fr 1fr 1fr 1.5fr;
      background: #e5e5e5;
    }\n
    .item {
      border: none;
      text-shadow: none;
      color: #000;
    }\n
    .title {
      grid-row: 1;
      grid-column: 1 / 3;
      z-index: 1;
      background: #f4d434;
      color: black;
      justify-content: start;
      align-items: end;
      text-transform: uppercase;
      font-weight: bold;
      font-size: calc(10px + 2.6vw);
      padding-left: 20px;
      display: grid;
    }\n
    .emph {
      transform: scaleY(1.5);
      white-space: nowrap;
    }\n
    .image {
      grid-row: 1 / 3;
      grid-column: 2 / 5;
      background: url(https://gedd.ski/img/divinity-dragon.jpg);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    }\n
    .content1 {
      grid-row: 2 / 4;
      grid-column: 1 / 2;
      overflow: hidden;
      font-size: 16px;
      display: block;
    }\n
    .content2 {
      grid-row: 3 / 4;
      grid-column: 2 / 4;
      overflow: hidden;
      font-size: 16px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 30px;
      align-items: start;
    }\n
    .content1, .content2 {
      padding: 10px 20px;
      font-size: 14px;
    }\n
    .quote {
      grid-row: 2;
      grid-column: 4;
      z-index: 2;
      background: rgba(0,0,0, .5);
      align-self: end;
      justify-self: start;
      position: relative;
      color: #e5d54f;
      border-left: 10px solid #e5d54f;
      padding: 10px;
      right: 125px;
      bottom: 6%;
      text-transform: uppercase;
      font-size: calc(12px + 1.1vw);
    }\n
    .score {
      grid-row: 3;
      background: #f4d434;
      width: 70%;
      height: 70%;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 5px;
      font-size: calc(12px + 2.6vw);
      font-weight: bold;
      text-transform: uppercase;
    }
  `} fixedCSS={`
    .magazine {
      width: 95vw;
      height: 800px;
      outline: 6px solid #121219;
      display: grid;
      grid-template-rows: 130px 2fr 1fr;
      grid-template-columns: 1.5fr 1fr 1fr 1.5fr;
      background: #e5e5e5;
    }
    .item {
      border: none;
      text-shadow: none;
      color: #000;
    }
    .title {
      grid-row: 1;
      grid-column: 1 / 3;
      z-index: 1;
      background: #f4d434;
      color: black;
      justify-content: start;
      align-items: end;
      text-transform: uppercase;
      font-weight: bold;
      font-size: calc(10px + 2.6vw);
      padding-left: 20px;
      display: grid;
    }
    .emph {
      transform: scaleY(1.5);
      white-space: nowrap;
    }
    .image {
      grid-row: 1 / 3;
      grid-column: 2 / 5;
      background: url(https://gedd.ski/img/divinity-dragon.jpg);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    }
    .content1 {
      grid-row: 2 / 4;
      grid-column: 1 / 2;
      overflow: hidden;
      font-size: 16px;
      display: block;
    }
    .content2 {
      grid-row: 3 / 4;
      grid-column: 2 / 4;
      overflow: hidden;
      font-size: 16px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 30px;
      align-items: start;
    }
    .content1, .content2 {
      padding: 10px 20px;
      font-size: 14px;
    }
    .quote {
      grid-row: 2;
      grid-column: 4;
      z-index: 2;
      background: rgba(0,0,0, .5);
      align-self: end;
      justify-self: start;
      position: relative;
      color: #e5d54f;
      border-left: 10px solid #e5d54f;
      padding: 10px;
      right: 125px;
      bottom: 6%;
      text-transform: uppercase;
      font-size: calc(12px + 1.1vw);
    }
    .score {
      grid-row: 3;
      background: #f4d434;
      width: 70%;
      height: 70%;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 5px;
      font-size: calc(12px + 2.6vw);
      font-weight: bold;
      text-transform: uppercase;
    }
  `} html={`
    <div class="magazine">
  <div class="item title orange"><span class="emph">Divinity: Original Sin 2</span></div>
  <div class="item content1 blue">
    <p>
      Bacon ipsum dolor amet cupim buffalo shankle, ham fatback turducken drumstick sirloin. Jowl buffalo venison shank pork belly alcatra, tenderloin bacon ribeye picanha spare ribs fatback ham hock. Sirloin pork pancetta, landjaeger ham hock biltong meatloaf
      brisket shoulder. Filet mignon ground round chuck turkey rump capicola.
    </p>
    <p>
      Flank fatback bacon jerky picanha brisket ball tip cupim. Jerky fatback shankle andouille picanha cupim pancetta pork chop tongue corned beef pork loin ham hock rump tri-tip t-bone. Meatball turducken burgdoggen cupim salami bresaola. Boudin turkey bresaola,
      short ribs ribeye short loin meatloaf corned beef picanha t-bone. Fatback beef cupim pork chop.
    </p>
    <p>
      Tail short loin tenderloin turducken. Pancetta bacon beef ribs turducken leberkas. Biltong kevin buffalo porchetta, chicken meatloaf doner chuck sirloin shankle swine. Boudin bacon chicken, cupim filet mignon salami venison chuck tongue ham tenderloin
      cow.
      Chuck doner ham, chicken burgdoggen jerky short ribs picanha rump tenderloin ground round boudin cow shoulder beef.
    </p>
    <p>
      Doner ribeye flank shankle bacon. Alcatra shoulder buffalo cow short loin shankle. Chicken chuck beef rump pork chop pork belly pork loin leberkas filet mignon kielbasa meatloaf andouille meatball. Burgdoggen sausage hamburger, drumstick prosciutto meatball
      buffalo pork belly ground round shoulder. Brisket filet mignon meatloaf, shankle salami jerky flank pig kevin porchetta sirloin. Brisket meatloaf andouille burgdoggen sirloin pancetta. Burgdoggen capicola leberkas picanha tail.
    </p>
    <p>
      Ham pork loin t-bone rump bresaola ham hock tail jerky salami turducken. Bresaola rump pork loin filet mignon chicken turducken sirloin short loin cupim jerky sausage tongue doner andouille. Andouille buffalo pork chop turducken meatball frankfurter,
      leberkas short loin flank burgdoggen ham hock beef ham salami. Fatback kevin pig boudin, bresaola capicola leberkas doner short ribs alcatra chuck jerky filet mignon shank. Filet mignon swine rump, meatball cow prosciutto shoulder strip steak frankfurter
      biltong. Turducken t-bone ham hock, cow porchetta shoulder strip steak. Turkey pork loin strip steak, ground round ham burgdoggen picanha cupim buffalo pork chop jerky short loin kevin cow ham hock.
    </p>
    <p>
      Hamburger leberkas pastrami landjaeger ball tip. Boudin tenderloin pork chuck burgdoggen tail. Shankle cow turkey short ribs pork loin picanha tongue buffalo pancetta beef. Tail strip steak kevin frankfurter prosciutto.
    </p>
    <p>
      Prosciutto corned beef sirloin, cow alcatra landjaeger tongue swine pork belly. Biltong t-bone kevin, swine pig ham capicola jowl drumstick prosciutto sirloin frankfurter. Bacon leberkas drumstick pork belly pork, shoulder jerky turducken. Picanha andouille
      beef ribs pork belly chuck ribeye cupim biltong. Tail jerky short loin, rump short ribs sirloin strip steak.
    </p>
  </div>
  <div class="item content2 blue">
    <p>
      Bacon ipsum dolor amet cupim buffalo shankle, ham fatback turducken drumstick sirloin. Jowl buffalo venison shank pork belly alcatra, tenderloin bacon ribeye picanha spare ribs fatback ham hock. Sirloin pork pancetta, landjaeger ham hock biltong meatloaf
      brisket shoulder. Filet mignon ground round chuck turkey rump capicola.
    </p>
    <p>
      Flank fatback bacon jerky picanha brisket ball tip cupim. Jerky fatback shankle andouille picanha cupim pancetta pork chop tongue corned beef pork loin ham hock rump tri-tip t-bone. Meatball turducken burgdoggen cupim salami bresaola. Boudin turkey bresaola,
      short ribs ribeye short loin meatloaf corned beef picanha t-bone. Fatback beef cupim pork chop.
    </p>
    <p>
      Tail short loin tenderloin turducken. Pancetta bacon beef ribs turducken leberkas. Biltong kevin buffalo porchetta, chicken meatloaf doner chuck sirloin shankle swine. Boudin bacon chicken, cupim filet mignon salami venison chuck tongue ham tenderloin
      cow.
      Chuck doner ham, chicken burgdoggen jerky short ribs picanha rump tenderloin ground round boudin cow shoulder beef.
    </p>
    <p>
      Doner ribeye flank shankle bacon. Alcatra shoulder buffalo cow short loin shankle. Chicken chuck beef rump pork chop pork belly pork loin leberkas filet mignon kielbasa meatloaf andouille meatball. Burgdoggen sausage hamburger, drumstick prosciutto meatball
      buffalo pork belly ground round shoulder. Brisket filet mignon meatloaf, shankle salami jerky flank pig kevin porchetta sirloin. Brisket meatloaf andouille burgdoggen sirloin pancetta. Burgdoggen capicola leberkas picanha tail.
    </p>
    <p>
      Ham pork loin t-bone rump bresaola ham hock tail jerky salami turducken. Bresaola rump pork loin filet mignon chicken turducken sirloin short loin cupim jerky sausage tongue doner andouille. Andouille buffalo pork chop turducken meatball frankfurter,
      leberkas short loin flank burgdoggen ham hock beef ham salami. Fatback kevin pig boudin, bresaola capicola leberkas doner short ribs alcatra chuck jerky filet mignon shank. Filet mignon swine rump, meatball cow prosciutto shoulder strip steak frankfurter
      biltong. Turducken t-bone ham hock, cow porchetta shoulder strip steak. Turkey pork loin strip steak, ground round ham burgdoggen picanha cupim buffalo pork chop jerky short loin kevin cow ham hock.
    </p>
    <p>
      Hamburger leberkas pastrami landjaeger ball tip. Boudin tenderloin pork chuck burgdoggen tail. Shankle cow turkey short ribs pork loin picanha tongue buffalo pancetta beef. Tail strip steak kevin frankfurter prosciutto.
    </p>
    <p>
      Prosciutto corned beef sirloin, cow alcatra landjaeger tongue swine pork belly. Biltong t-bone kevin, swine pig ham capicola jowl drumstick prosciutto sirloin frankfurter. Bacon leberkas drumstick pork belly pork, shoulder jerky turducken. Picanha andouille
      beef ribs pork belly chuck ribeye cupim biltong. Tail jerky short loin, rump short ribs sirloin strip steak.
    </p>
  </div>
  <div class="item quote green">We both died. Twice. <br/> But I still felt really <br/> good about my decision.</div>
  <div class="item image red"></div>
  <div class="item score yellow"><span class="emph">score: 92</span></div>
</div>
  `} mdxType="CodeExample" />
    <p>{`Not bad eh?! We could spend more time making it pixel-perfect and mobile friendly, but this is good enough for this exercise.`}</p>
    <p>{`One interesting thing to check out is that quote box that's overlapping the game image. It's positioned into the 2nd row, 4th column. But we used `}<inlineCode parentName="p">{`position: relative`}</inlineCode>{` to shift it up and to the left to `}<em parentName="p">{`break out`}</em>{` of the grid a bit, just like it does in the magazine. Keep an eye out for this. Oftentimes layouts that don't appear to be a grid actually are, just with some clever item shifting.`}</p>
    <h2>{`Overlap your Grid Items`}</h2>
    <p>{`Creating magazine-style layouts on the web has never been easier thanks to CSS Grid and the ability to overlap grid items.`}</p>
    <p>{`There are also a ton of cool effects you can achieve by overlapping grid items. For example combining it with `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/CSS/background-blend-mode"
      }}>{`background blend modes`}</a>{`. You can even mimic `}<a parentName="p" {...{
        "href": "https://codepen.io/geddski/pen/JZRybV"
      }}>{`styling grid gaps`}</a>{`.`}</p>
    <p>{`Go try it yourself! Tear a page out of any physical magazine — at the dentist, your grandma's, wherever you can find one. Build that magazine layout using CSS Grid and overlapping some of the grid items. Don't forget `}<inlineCode parentName="p">{`z-index`}</inlineCode>{` for the stacking order. Have fun!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      